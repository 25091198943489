<template>
  <div class="banner-item" @click="handleClick()">
    <CommonBaseImg :src="item.img" :alt="item?.name" lazy />
  </div>
</template>
<script setup>
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['click'])
const handleClick = () => {
  emit('click')
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/hero-banner/banner-item.scss" />
